export const legalListStyle = `
> ol {
  counter-reset: first-level;

  > li {
    list-style-type: none;
    counter-increment: first-level;

    &::before {
      content: counter(first-level) '. ';
      font-weight: bold;
    }

    > ol {
      counter-reset: second-level;

      > li {
        list-style-type: none;
        counter-increment: second-level;

        &::before {
          content: counter(first-level) '.' counter(second-level) ' ';
          font-weight: bold;
        }

        > ol {
          counter-reset: third-level;

          > li {
            list-style-type: none;
            counter-increment: third-level;

            &::before {
              content: '(' counter(third-level, lower-alpha) ')';
              padding-right: 4px;
            }
          }
        }
      }
    }
  }
}
`;
