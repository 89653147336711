import React from 'react';

import {graphql} from 'gatsby';
import {Box, LayoutGrid, StyledRichContent, SEOWithQuery} from 'src/components/shared';
import {Typography} from 'src/components/shared/typography';
import {Icon, Icons} from 'src/svgs';
import * as copy from 'src/utils/copy';
import {legalListStyle} from 'src/utils/styles';
import styled from 'styled-components/macro';

import {MembershipAgreementCorporatePageQuery} from 'graphql-types';

const {P0, P2} = Typography;

export const query = graphql`
  query MembershipAgreementCorporatePage {
    doc: sanityPublicMembershipAgreementCorporate(_id: {eq: "publicMembershipAgreementCorporate"}) {
      heading
      subheading
      content: _rawContent
      copyright
    }
  }
`;

type MembershipAgreementProps = {
  data: MembershipAgreementCorporatePageQuery;
};

const RichContent = styled(StyledRichContent)`
  ${legalListStyle}
`;

export const MembershipAgreementCorporate: React.FC<MembershipAgreementProps> = ({data}) => {
  return (
    <Box backgroundColor="darkGreen">
      <LayoutGrid>
        <Box gridColumn="1/end">
          <Box textAlign="center" pt="83px" pb="43px" mb="32px">
            <Box width="150px" mb="34px" mx="auto">
              <Icon aria-label={copy.membershipAgreement.ariaLabels.logo} icon={Icons.logo} color="cream" />
            </Box>
            <P0 color="cream" as="h1" mb="21px">
              {data.doc?.heading ?? copy.membershipAgreementCorporate.heading}
            </P0>
            <P0 color="cream" mb="21px">
              {data.doc?.subheading ?? copy.membershipAgreementCorporate.subheading}
            </P0>
          </Box>
          <RichContent blocks={data.doc?.content} />
          {!!data.doc?.copyright && (
            <>
              <Box as="hr" my="24px" />
              <P2 color="cream" textAlign="center" mb="40px">
                {data.doc.copyright}
              </P2>
            </>
          )}
        </Box>
      </LayoutGrid>
      <SEOWithQuery title={copy.seo.membershipAgreementCorporateTitle} noIndex />
    </Box>
  );
};

export default MembershipAgreementCorporate;
